import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Modal, ModalBody, ModalHeader, FormGroup, Input } from 'reactstrap';
import { useUpdateChannelPreferences } from '../../api-hooks/channel/preference';
import { useEnableNotifications } from '../../api-hooks/notify/notify';
import { useRegisterAndSubscribe } from '../../lib/serviceWorkerHook';
import { ButtonPill, ButtonPillOutline } from '../Button';
import { LOCAL_STORAGE_WEB_PUSH_KEY, useWebPush } from './Provider';

export const WebPushModal = () => {
	const { isOpen, closeModal } = useWebPush();
	const [askAgain, setAskAgain] = useState(true);
	const registerAndSubscribe = useRegisterAndSubscribe();
	const { mutate: enableNotifications } = useEnableNotifications();
	const { mutate } = useUpdateChannelPreferences();
	const { t } = useTranslation();

	const handleChange = () => {
		setAskAgain(!askAgain);
		localStorage.setItem(LOCAL_STORAGE_WEB_PUSH_KEY, askAgain ? '3' : '0');
	};

	const handleSubmit = useCallback(async () => {
		mutate({ webPush: true });
		enableNotifications();
		registerAndSubscribe();
		localStorage.setItem(LOCAL_STORAGE_WEB_PUSH_KEY, 3);
		closeModal();
	}, [closeModal, enableNotifications, mutate, registerAndSubscribe]);

	return (
		<Modal
			isOpen={isOpen}
			toggle={closeModal}
			zIndex={2001}
			className="position-absolute mr-3 mb-3 top-0 right-0 text-black"
		>
			<ModalHeader toggle={closeModal}>
				<strong>{t('WebPush.Modal.Header')} </strong>
			</ModalHeader>
			<ModalBody>
				<p>{t('WebPush.Modal.Text')}</p>

				<div className="d-flex justify-content-end">
					<ButtonPill
						className="CookiesApproval_Icon_Button ml-2 mb-1 flex-shrink-0"
						color="primary"
						title="Install"
						onClick={handleSubmit}
					>
						{t('WebPush.Modal.Allow')}
					</ButtonPill>
					<ButtonPillOutline
						className="CookiesApproval_Icon_Button ml-2 mb-1 flex-shrink-0"
						color="secondary"
						title="Cancel"
						onClick={closeModal}
					>
						{t('WebPush.Modal.Close')}
					</ButtonPillOutline>
				</div>
				<div className="text-end mt-3">
					<FormGroup check id="askAgainBox">
						<div className="d-flex justify-content-end">
							<Input
								id="askAgain"
								type="checkbox"
								checked={!askAgain}
								onChange={handleChange}
								className="mr-2"
								style={{ outline: '1px solid black' }}
							/>
							{t('WebPush.Modal.DontAsk')}
						</div>
					</FormGroup>
				</div>

			</ModalBody>
		</Modal>
	);
};
